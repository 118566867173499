import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Dropdown, Dimmer, Loader, Grid, Item, Checkbox, Input } from 'semantic-ui-react';
import './AdminPage.css';
//import moment from 'moment';
//import BigCalendarWrapperNoOutlook from './BigCalendarWrapperNoOutlook';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';
//import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker, InfoWindow } from 'react-google-maps';
import './MapMarker.css';
import polyline from '@mapbox/polyline';
//import { withProps, withStateHandlers} from 'recompose';
//import { compose, bindActionCreators} from 'redux';
import AdminGoogleMap from './Admin_GoogleMap';

var moment = require('moment');

const cancelOptions = [
    { key: 1, text: 'Appointment no longer convenient', value: 0 },
    { key: 2, text: 'Appt cannot proceed due to third party input required', value: 1 },
    { key: 3, text: 'Client capacity concerns', value: 2 },
    { key: 4, text: 'Client covid-19 isolation', value: 3 },
    { key: 5, text: 'Client not sure if wants to proceed', value: 4 },
    { key: 6, text: 'Client unwell', value: 5 },
    { key: 7, text: 'Equilaw Covid cancellation', value: 6 },
    { key: 8, text: 'Equilaw other', value: 7 },
]

class BookApptPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //
            //sendConfirmations: true,
            //
            minSearchLength: 3,
            loadedAgents: false,
            loadedPostcodes: false,
            selectedAgent: "",
            futureDayReach: 21,

            segment_editUser_agent: "IQ-Hidden",

            agentOptions: [],
            formCSS: "Admin-Edit-Hide",
            dimmerActive: true,
            editApptsLoading: "",
            cutOffTime: new Date(),
            afterEndCutoff_CSS: "IQ-Hidden",
            scheduleRoutingEndTime: "",

            loadedRotas: false,
            showScheduleDiv_CSS: "IQ-Hidden",

            httpResultModalOpen: false,
            httpResultModalIcon: "warning circle red",
            httpResultModalHeader: "Agent Coverage Update Fail!",
            httpResultModalText: "Default error text",

            loadedAppts: false,
            dataAgentApptList: [],
            polyline: "",
            polylineDecoded: [],
            //apptWaypointOrder: [],
            routingStartTime: "",
            stateIsOpen: [],

            editTimeModalOpen: false,
            timeOptions_Hour: [
                { key: 0, text: "00", value: 0 },
                { key: 1, text: "01", value: 1 },
                { key: 2, text: "02", value: 2 },
                { key: 3, text: "03", value: 3 },
                { key: 4, text: "04", value: 4 },
                { key: 5, text: "05", value: 5 },
                { key: 6, text: "06", value: 6 },
                { key: 7, text: "07", value: 7 },
                { key: 8, text: "08", value: 8 },
                { key: 9, text: "09", value: 9 },
                { key: 10, text: "10", value: 10 },
                { key: 11, text: "11", value: 11 },
                { key: 12, text: "12", value: 12 },
                { key: 13, text: "13", value: 13 },
                { key: 14, text: "14", value: 14 },
                { key: 15, text: "15", value: 15 },
                { key: 16, text: "16", value: 16 },
                { key: 17, text: "17", value: 17 },
                { key: 18, text: "18", value: 18 },
                { key: 19, text: "19", value: 19 },
                { key: 20, text: "20", value: 20 },
                { key: 21, text: "21", value: 21 },
                { key: 22, text: "22", value: 22 },
                { key: 23, text: "23", value: 23 },
            ],
            timeOptions_Minute: [
                { key: 0, text: "00", value: 0 },
                { key: 1, text: "15", value: 15 },
                { key: 2, text: "30", value: 30 },
                { key: 3, text: "45", value: 45 },
            ],

            createSchedule_CSS: "Admin-Edit-Hide",
            schedDate: moment(moment(), 'MM-DD-YYYY'),
            scheduleId: 0,
            schedCreatedOK: false,
            viewScheduleButton_CSS: "",
            changeTimeButton_CSS: "disabled",
            newTime_Hour: 0,
            newTime_Minutes: 0,
            changeStartOrEnd: "",

            bookApptModalOpen: false,
            bookModalButton_CSS: "",
            createScheduleButton_CSS: "Admin-Hidden",
            divFileInfo_CSS: "IQ-Hidden",
            matterOptions: [],
            searchQuery: "",
            searching: false,
            alterPostcodeButton_CSS: "",
            cancelButton_CSS: "",
            cancelApptId: 0,
            cancelModalOpen: false,
            cancelModalButton_CSS: "",
            cancelSuccess: false,
            cancelReason: "",

            apptAtPoa: "",
            input_appt_custom_postcode: "",
            showPOANotificationchoice_CSS: "IQ-Hidden",
            showDEFAULTNotificationchoice_CSS: "",
            poaMobileOptions: [],
            chosenMobileOption: 0,

            alterModalOpen: false,
            input_sched_custom_postcode: "",
            sched_appt_reference: "",

            smsButton_CSS: "",
            smsModalOpen: false,
            sms_apptId: 0,
            input_sched_sms_num: "",
            smsModalButton_CSS: "",

            googleAPIKey: "AIzaSyC8UK5QfaqlfF_r9e4OUG__h90qhUfTDGs",

            mapH: "700px",
            mapW: "100%",
            mapCentre: { lat: 51.847389, lng: -2.177888 },
            mapZoom: 12,

            fileId: (this.props.history.location.search.split("id=")[1] > 0) ? this.props.history.location.search.split("id=")[1] : 0,
        }
        //
        this.valueChange = this.valueChange.bind(this);
        this.dataLoadedChecker = this.dataLoadedChecker.bind(this);
        //        
        this.loadExistingAgentList_Wrapper = this.loadExistingAgentList_Wrapper.bind(this);
        this.loadExistingAgentList = this.loadExistingAgentList.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        //
        this.loadAgentSchedule_Wrapper = this.loadAgentSchedule_Wrapper.bind(this);
        this.loadAgentSchedule = this.loadAgentSchedule.bind(this);
        this.updateStartTime_Wrapper = this.updateStartTime_Wrapper.bind(this);
        this.updateStartTime = this.updateStartTime.bind(this);
        this.onChangeNewTimeHours = this.onChangeNewTimeHours.bind(this);
        this.onChangeNewTimeMinutes = this.onChangeNewTimeMinutes.bind(this);
        //
        this.onClickBookApptButton = this.onClickBookApptButton.bind(this);
        this.bookAppt_Wrapper = this.bookAppt_Wrapper.bind(this);
        this.bookAppt = this.bookAppt.bind(this);
        this.handleMatterChange = this.handleMatterChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getMatterInfoFromFileId_Wrapper = this.getMatterInfoFromFileId_Wrapper.bind(this);
        this.getMatterInfoFromFileId = this.getMatterInfoFromFileId.bind(this);
        this.getMattersFromReferenceSearch = this.getMattersFromReferenceSearch.bind(this);
        this.onClick_CancelButton = this.onClick_CancelButton.bind(this);
        this.cancelAppt_Wrapper = this.cancelAppt_Wrapper.bind(this);
        this.cancelAppt = this.cancelAppt.bind(this);
        this.handleCancelReasonChange = this.handleCancelReasonChange.bind(this);
        this.getApptDateForApptId = this.getApptDateForApptId.bind(this);
        //
        this.onClick_alterPostcodeButton = this.onClick_alterPostcodeButton.bind(this);
        this.onClick_alterPostcodeSubmit = this.onClick_alterPostcodeSubmit.bind(this);
        this.alterSchedStartFinishPostcode_Wrapper = this.alterSchedStartFinishPostcode_Wrapper.bind(this);
        this.alterSchedStartFinishPostcode = this.alterSchedStartFinishPostcode.bind(this);
        //
        this.onChange_newSchedDate = this.onChange_newSchedDate.bind(this);
        this.onClickViewScheduleButton = this.onClickViewScheduleButton.bind(this);
        this.createSchedule = this.createSchedule.bind(this);
        //
        this.sendSMS = this.sendSMS.bind(this);
        this.onClick_SmsButton = this.onClick_SmsButton.bind(this);
        this.onClick_smsModalSubmit = this.onClick_smsModalSubmit.bind(this);
        //
        this.getMatterMobileNumberChoices = this.getMatterMobileNumberChoices.bind(this);
        this.getMatterMobileNumberChoices_Wrapper = this.getMatterMobileNumberChoices_Wrapper.bind(this);
        this.handleMobileOptionChange = this.handleMobileOptionChange.bind(this);
        //
        this.createSchedule = this.createSchedule.bind(this);
        //
        this.loadExistingAgentList_Wrapper(this.props.url);
        setTimeout(() => {
            this.dataLoadedChecker();
            this.getMatterInfoFromFileId_Wrapper(this.props.url);
        }, 150)
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({ [key]: e.target.value });
    /////////////////////////////////////////////////////////////////
    //
    //
    dataLoadedChecker() {
        if (this.state.loadedAgents) {
            this.setState({
                dimmerActive: false,
            });
        }
        else {
            setTimeout(() => {
                this.dataLoadedChecker();
            }, 100)
        }
    }
    //
    //
    handleAgentChange(event, data) {
        this.setState({
            selectedAgent: data.value,
        });
        console.log("Agent Id: " + data.value);
    }
    //
    onChange_newSchedDate(value) {
        //console.log("changing date!!");
        var d = moment(value, 'MM-DD-YYYY').add(1, "h");
        //console.log("new sched date = " + d);
        if (this.state.schedDate !== d) {
            this.setState({
                schedDate: d,
            });
        }
    }
    //
    loadAgentSchedule_Wrapper(url) {
        console.log("Started loading Schedule...");
        console.log("Loading schedule for date: " + this.state.schedDate.toString("DD-MM-YYYY"));
        var loadDateStart = new Date();
        this.setState({
            dimmerActive: true,
        });
        var result = this.loadAgentSchedule(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    console.log(data.body);
                    var polyLine = data.body.polyline;
                    if (polyLine !== null) {
                        var plData = polyline.decode(polyLine);
                        var latLngPlData = [];
                        for (var i = 0; i < plData.length; i++) {
                            latLngPlData.push({ lat: plData[i][0], lng: plData[i][1] });
                        }
                    }
                    else polyLine = "";
                    //
                    var startTimeDate = new Date(data.body.NominatedRoutingTime);
                    var endTimeDate = new Date(data.body.NominatedRoutingEndTime);
                    var scheduleTotalSeconds = data.body.scheduleDuration_Seconds;
                    console.log("scheduleTotalSeconds: " + scheduleTotalSeconds);
                    var tempRoutingEndTime = moment(startTimeDate).add(scheduleTotalSeconds, 's').toDate();
                    //var totalApptDuration = (data.body.apptList.length-2)*(data.body.apptDuration*60);
                    //scheduleTotalSeconds += totalApptDuration;
                    //console.log("scheduleTotalSeconds (with appt duration): " + scheduleTotalSeconds);
                    var routingTimeDifference = Math.abs((endTimeDate.getTime() - startTimeDate.getTime()) / 1000);
                    console.log("scheduleTotalAvailableSeconds: " + routingTimeDifference);
                    var hours = startTimeDate.getHours() > 9 ? startTimeDate.getHours() : "0" + startTimeDate.getHours();
                    var minutes = startTimeDate.getMinutes() > 9 ? startTimeDate.getMinutes() : "0" + startTimeDate.getMinutes();
                    var centerLat = data.body.apptList !== null ? data.body.apptList[0].postcodeLat : 51.847389;
                    var centerLng = data.body.apptList !== null ? data.body.apptList[0].postcodeLon : -2.177888;
                    var latLng = { lat: centerLat, lng: centerLng };
                    var loadDateEnd = new Date();
                    var loadingMillis = loadDateEnd - loadDateStart;
                    console.log("Finished loading Schedule! Time = " + Math.floor((loadingMillis / 1000) % 60));
                    //
                    var tempDate = new Date(data.body.ScheduleDate);
                    tempDate.setDate(tempDate.getDate() - 1);
                    var cutOffDate = new Date(data.body.CutOffTime);
                    tempDate.setHours(cutOffDate.getHours());
                    tempDate.setMinutes(cutOffDate.getMinutes());
                    tempDate.setSeconds(0);
                    var isNowPastCutOff = (new Date() > tempDate);
                    var scheduleDate = new Date(data.body.ScheduleDate);
                    var today = new Date();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    var dateIsEarlierThanToday = scheduleDate < today;
                    this.setState({
                        dataAgentApptList: data.body.apptList !== null ? data.body.apptList : [],
                        dimmerActive: false,
                        viewScheduleButton_CSS: "",
                        showScheduleDiv_CSS: "",
                        //apptWaypointOrder: data.body.waypointOrder,
                        polyline: polyLine,
                        polylineDecoded: latLngPlData,
                        mapCentre: latLng,
                        routingStartTime: hours + ":" + minutes + (hours < 12 ? " am" : " pm"),
                        changeTimeButton_CSS: (isNowPastCutOff || dateIsEarlierThanToday ? " disabled" : ""),
                        scheduleId: data.body.ScheduleId,
                        cutOffTime: tempDate,
                        bookModalButton_CSS: this.props.url.includes("localhost") ? "" : (isNowPastCutOff || dateIsEarlierThanToday ? " disabled" : ""),
                        alterPostcodeButton_CSS: (isNowPastCutOff || dateIsEarlierThanToday ? " disabled" : ""),
                        smsButton_CSS: "",
                        scheduleRoutingEndTime: (endTimeDate.getHours() < 10 ? "0" + endTimeDate.getHours() : endTimeDate.getHours()) + ":" + (endTimeDate.getMinutes() < 10 ? "0" + endTimeDate.getMinutes() : endTimeDate.getMinutes()),
                        afterEndCutoff_CSS: scheduleTotalSeconds < routingTimeDifference ? "IQ-Hidden" : "",
                        cancelButton_CSS: (dateIsEarlierThanToday ? " disabled" : ""),
                        createScheduleButton_CSS: data.body.ScheduleId != null && data.body.ScheduleId > 0 ? "Admin-Hidden" : "",
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadAgentSchedule(url) {
        const response = await fetch(url + "/Schedule/GetAgentScheduleForDate_ForPortal", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.selectedAgent,
                schedDate: this.state.schedDate
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingAgentList_Wrapper(url) {
        var result = this.loadExistingAgentList(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        agentOptions: data.body,
                        loadedAgents: true,
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingAgentList(url) {
        const response = await fetch(url + "/Account/GetListOfAllAgents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    updateStartTime_Wrapper(url) {
        var result = this.updateStartTime(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editTimeModalOpen: false,
                    });
                    this.loadAgentSchedule_Wrapper(url);
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async updateStartTime(url) {
        var tempDate = new Date(this.state.schedDate);
        tempDate.setHours(this.state.newTime_Hour);
        tempDate.setMinutes(this.state.newTime_Minutes + Math.abs(tempDate.getTimezoneOffset()));
        const response = await fetch(url + "/Schedule/UpdateScheduleStartTime", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleId: this.state.scheduleId,
                scheduleDate: tempDate//newTimeDate.format("dd/MM/YYYY HH:mm:ss"),
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    updateEndTime_Wrapper(url) {
        var result = this.updateEndTime(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editTimeModalOpen: false,
                    });
                    this.loadAgentSchedule_Wrapper(url);
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async updateEndTime(url) {
        var tempDate = new Date(this.state.schedDate);
        tempDate.setHours(this.state.newTime_Hour);
        tempDate.setMinutes(this.state.newTime_Minutes + Math.abs(tempDate.getTimezoneOffset()));
        const response = await fetch(url + "/Schedule/UpdateScheduleEndTime", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleId: this.state.scheduleId,
                scheduleDate: tempDate//newTimeDate.format("dd/MM/YYYY HH:mm:ss"),
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClickViewScheduleButton() {
        this.setState({
            viewScheduleButton_CSS: "disabled loading",
        });
        let newSchedDate = new Date(this.state.schedDate);
        console.log("newSchedDate: " + newSchedDate);
        if (newSchedDate != null && newSchedDate.getFullYear() > 2000) {
            this.loadAgentSchedule_Wrapper(this.props.url);
        }
        else {
            this.setState({
                viewScheduleButton_CSS: "",
            });
        }
    }
    //
    createSchedule_Wrapper(url) {
        this.setState({
            createSchedule_CSS: "disabled loading",
        });
        var result = this.createSchedule(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        createSchedule_CSS: "disabled",
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async createSchedule(url) {
        const response = await fetch(url + "/Schedule/CreateScheduleForAgent", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentId: this.state.selectedAgent,
                scheduleDate: this.state.schedDate,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onChangeNewTimeHours(event, data, startOrEnd) {
        this.setState({
            newTime_Hour: data.value
        });
    }
    //
    onChangeNewTimeMinutes(event, data, startOrEnd) {
        this.setState({
            newTime_Minutes: data.value
        });
    }
    //
    onClickBookApptButton() {
        this.setState({
            bookApptModalOpen: true,
            bookModalButton_CSS: "disabled loading",
        });
    }
    //
    bookAppt_Wrapper(val) {
        var results = this.bookAppt(this.props.url);
        results.then((data) => {
            switch (data.httpCode) {
                case 200:
                    //console.log("appt booking result: " + data.body);  
                    this.setState({
                        bookingResultIcon: "checkmark green icon",
                        bookingResultHeader: "Booking Success!",
                        bookingResultText: "Your chosen appointment has been successfully booked. You will receive confirmation of your booking via email.",
                        bookedModalOpen: true,
                        bookApptModalOpen: false,
                        apptBookedOK: true,
                        bookingButton_CSS: "",
                        bookModalButton_CSS: "",
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: data.body.Message + " Please attempt your booking again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                        bookApptModalOpen: false,
                        bookingButton_CSS: "",
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async bookAppt(url, val) {
        let newApptDate = new Date(this.state.schedDate);
        console.log("appt date hour = " + newApptDate.getHours());
        //
        if (newApptDate.getFullYear() > 2000) {
            const response = await fetch(url + "/Schedule/BookApptForPortal_NoOutlook", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: localStorage.token,
                    scheduleId: this.state.scheduleId,
                    fileId: this.state.fileId,
                    //sendConfirmations: this.state.sendConfirmations,
                    customPostcode: this.state.input_appt_custom_postcode,
                    usingCustomPostcode: (this.state.input_appt_custom_postcode !== null && this.state.input_appt_custom_postcode !== "")
                })
            });
            const responseObject = {
                httpCode: response.status,
                body: response.status === 200 || response.status === 400 ? await response.json() : ""
            }
            return responseObject;
        }
        else {
            const responseObject = {
                httpCode: "FAIL",
                body: "FAIL"
            }
            return responseObject;
        }
    }
    //
    getMatterInfoFromFileId_Wrapper(url) {
        if (this.state.fileId != null && this.state.fileId > 0) {
            var result = this.getMatterInfoFromFileId(url);
            result.then((data) => {
                switch (data.httpCode) {
                    case 200:
                        //console.log(data.body);  
                        this.setState({
                            fileInfo: data.body,
                            divFileInfo_CSS: "",
                            divMatterSearch_CSS: "IQ-Hidden",
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Booking Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookedModalOpen: true,
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                divFileInfo_CSS: "IQ-Hidden",
                divMatterSearch_CSS: "",
            });
        }
    }
    //
    async getMatterInfoFromFileId(url) {
        const response = await fetch(url + "/Matters/GetMatterInfoFromFileId", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.fileId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleMatterChange(event, data) {
        this.setState({
            fileId: data.value,
        });
        //
        var result = this.fetchCustomPostcodeData(this.props.url, data.value);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    console.log(data.body);
                    this.setState({
                        apptAtPoa: data.body.apptAtPOAPostcode,
                        input_appt_custom_postcode: data.body.poaPostcode,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchCustomPostcodeData(url, fileId) {
        const response = await fetch(url + "/Schedule/GetMatterPOAPostcodeData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: fileId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleSearchChange = (e, { searchQuery }) => {
        //console.log("Search query: " + searchQuery);
        if (searchQuery.length >= this.state.minSearchLength && searchQuery !== "001" && searchQuery !== "002") {
            this.setState({
                searchQuery: searchQuery,
                searching: true,
            });
            var result = this.getMattersFromReferenceSearch(this.props.url, searchQuery);
            result.then((data) => {
                switch (data.httpCode) {
                    case 200:
                        //console.log(data.body);  
                        this.setState({
                            matterOptions: data.body,
                            searching: false,
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Booking Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookApptModalOpen: false,
                            bookedModalOpen: true,
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                matterOptions: [],
                searching: false,
            });
        }
    }
    //
    async getMattersFromReferenceSearch(url, searchQuery) {
        const response = await fetch(url + "/Matters/GetMattersByPartialReference_ForDropdown", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                matRef: searchQuery,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getMatterMobileNumberChoices_Wrapper(apptId) {
        var result = this.getMatterMobileNumberChoices(this.props.url, apptId);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        smsModalOpen: true,
                        //
                        poaMobileOptions: data.body,
                        showPOANotificationchoice_CSS: "",
                        showDEFAULTNotificationchoice_CSS: "IQ-Hidden",
                    });
                    console.log(this.state.poaMobileOptions);
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async getMatterMobileNumberChoices(url, apptId) {
        const response = await fetch(url + "/Schedule/GetMatterMobileNumberChoices", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                apptId: apptId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleMobileOptionChange(event, data) {
        this.setState({
            chosenMobileOption: data.value,
        });
    }
    //
    onClick_CancelButton(event, data) {
        this.setState({
            //dimmerActive: true,
            cancelApptId: data.value,
            cancelButton_CSS: "disabled loading",
            cancelModalOpen: true,
        });
        console.log("cancel apptId: " + data.value);
        //this.cancelAppt_Wrapper(data.value);
    }
    //
    onClick_CancelModalButton() {
        if (this.state.cancelApptId > 0) {
            this.setState({
                dimmerActive: true,
                cancelModalOpen: false,
            });
            this.cancelAppt_Wrapper();
        }
    }
    //
    cancelAppt_Wrapper() {
        var results = this.cancelAppt(this.props.url);
        results.then((data) => {
            switch (data.httpCode) {
                case 200:
                    //console.log("appt booking result: " + data.body);  
                    this.setState({
                        httpResultModalIcon: "checkmark green icon",
                        httpResultModalHeader: "Cancel Success!",
                        httpResultModalText: "Your chosen appointment has been successfully cancelled.",
                        httpResultModalOpen: true,
                        //
                        cancelButton_CSS: "",
                        cancelModalOpen: false,
                        cancelModalButton_CSS: "",
                        cancelSuccess: true,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        httpResultModalIcon: "warning circle red",
                        httpResultModalHeader: "Cancel Error!",
                        httpResultModalText: data.body.Message + " Please try again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        httpResultModalOpen: true,
                        cancelButton_CSS: "",
                        cancelModalOpen: false,
                        cancelModalButton_CSS: "",
                        cancelSuccess: false,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async cancelAppt(url, apptId) {
        var tempDate = this.getApptDateForApptId(apptId);
        //
        const response = await fetch(url + "/Schedule/CancelApptForPortal_NoOutlook", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                apptId: this.state.cancelApptId,
                apptDate: tempDate,
                cancellationReason: cancelOptions[this.state.cancelReason].text
                //sendConfirmations: this.state.sendConfirmations,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getApptDateForApptId(_apptId) {
        var appts = this.state.dataAgentApptList;
        var element = appts.find(function findAppt(_apptId) { return function (element) { return element.apptId === _apptId; } });
        if (element != null) return element.apptDate;
        else return null;
    }
    //
    onClick_alterPostcodeButton(event) {
        this.setState({
            sched_appt_reference: event.target.value,
            alterModalOpen: true,
        });
    }
    //
    onClick_alterPostcodeSubmit() {
        this.alterSchedStartFinishPostcode_Wrapper();
    }
    //
    alterSchedStartFinishPostcode_Wrapper() {
        var results = this.alterSchedStartFinishPostcode(this.props.url);
        results.then((data) => {
            switch (data.httpCode) {
                case 200:
                    //console.log("appt booking result: " + data.body);  
                    this.setState({
                        httpResultModalIcon: "checkmark green icon",
                        httpResultModalHeader: "Postcode override Success!",
                        httpResultModalText: "The " + ((this.state.sched_appt_reference === "START") ? "starting" : "finishing") + " postcode for this schedule has been updated.",
                        httpResultModalOpen: true,
                        //
                        alterModalOpen: false,
                        alterModalButton_CSS: "",
                        alterSuccess: true,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        httpResultModalIcon: "warning circle red",
                        httpResultModalHeader: "Postcode override Error!",
                        httpResultModalText: data.body.Message + " Please try again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        httpResultModalOpen: true,
                        alterModalOpen: false,
                        alterModalButton_CSS: "",
                        alterSuccess: false,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async alterSchedStartFinishPostcode(url) {
        const response = await fetch(url + "/Schedule/AlterAgentScheduleTempPostcode", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleId: this.state.scheduleId,
                newPostcode: this.state.input_sched_custom_postcode,
                alterStart: (this.state.sched_appt_reference === "START"),
                alterFinish: (this.state.sched_appt_reference === "FINISH")
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_SmsButton(event, data) {
        var usingPOA = false;
        for (let i = 0; i < this.state.dataAgentApptList.length; i++) {
            if (this.state.dataAgentApptList[i].apptId === data.value) {
                usingPOA = this.state.dataAgentApptList[i].apptUsingPOA;
            }
        }
        console.log("usingPOA? " + usingPOA);
        if (usingPOA) {
            this.setState({
                smsButton_CSS: " loading disabled",
                sms_apptId: data.value,
                input_sched_sms_num: data.num,
            })
            this.getMatterMobileNumberChoices_Wrapper(data.value);
        }
        else {
            this.setState({
                smsButton_CSS: " loading disabled",
                sms_apptId: data.value,
                input_sched_sms_num: data.num,
                smsModalOpen: true,
                showPOANotificationchoice_CSS: usingPOA ? "" : "IQ-Hidden",
                showDEFAULTNotificationchoice_CSS: (!usingPOA) ? "" : "IQ-Hidden",
            })
        }
    }
    //
    onClick_smsModalSubmit() {
        console.log("chosen mobile number: " + this.state.chosenMobileOption);
        var mobileOverride = "";
        if (this.state.poaMobileOptions != null && this.state.poaMobileOptions.length > 0) {
            var temp = this.state.poaMobileOptions[this.state.chosenMobileOption].text;
            temp = temp.split(":")[1].trim();
            mobileOverride = temp;
        }
        var results = this.sendSMS(this.props.url, mobileOverride);
        results.then((data) => {
            switch (data.httpCode) {
                case 200:
                    //reload schedule view
                    this.setState({
                        smsModalOpen: false,
                        smsModalButton_CSS: ""
                    });
                    this.loadAgentSchedule_Wrapper(this.props.url);
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        smsButton_CSS: "",
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async sendSMS(url, mobileOverride) {
        const response = await fetch(url + "/Schedule/SendBookingDateSMS", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleId: this.state.scheduleId,
                apptId: this.state.sms_apptId,
                mobileOverride: mobileOverride
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }

    handleCancelReasonChange(event, data) {
        console.log("cancelReason: " + cancelOptions[data.value].text);
        this.setState({
            cancelReason: data.value
        });
    }
    //
    render() {

        return (
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="address card large" />&emsp;Book Agent Appointments</h5></Label>
                    <br /><br /><br />
                    <div>
                        <Form>
                            <Form.Field className="Admin-Div-Float-Left">
                                <div className="Admin-Div-Company-Region">
                                    <div className="Admin-Div-Company-Outer">
                                        <label>Select Agent</label>
                                        <div className="Admin-Div-Company">
                                            <Dropdown fluid={true} selection={true} search={true} placeholder="Select Agent..." value={this.state.selectedAgent} options={this.state.agentOptions} onChange={this.handleAgentChange} />
                                        </div>
                                    </div>
                                </div>
                            </Form.Field>
                            <Form.Field className={"Admin-Div-CreateSchedule"}>
                                <br />
                                <label className="Admin-CreateScheduleLabel">Select Date</label>
                                <div className="Admin-DivCalendar">
                                    <Calendar
                                        id="input_clients_1_DOB"
                                        format='ddd DD/MMM/YYYY'
                                        displayYrWithMonth={true}
                                        closeOnSelect={true}
                                        openOnInputFocus={true}
                                        //minDate = {(new Date()).getDate() + "/" + (new Date()).getMonth() + "/" + (new Date()).getFullYear()}
                                        minDate={"1/11/2019"}
                                        readOnly={true}
                                        inputFieldClass="input-calendar-value IQ-Calendar"
                                        date={this.state.schedDate}
                                        onChange={this.onChange_newSchedDate}
                                        locale="en-gb"
                                    //customIcon="calendar outline icon"
                                    />
                                </div>
                                <div className="Admin-Div-Book-Buttons">
                                    <Button className={"ui button blue " + this.state.viewScheduleButton_CSS} onClick={this.onClickViewScheduleButton}>View</Button>
                                &emsp;
                                <Button className={"ui button green " + this.state.changeTimeButton_CSS} onClick={() => { this.setState({ editTimeModalOpen: true, changeStartOrEnd: "start" }); }} >Change Start Time</Button>
                                &emsp;
                                <Button className={"ui button orange " + this.state.changeTimeButton_CSS} onClick={() => { this.setState({ editTimeModalOpen: true, changeStartOrEnd: "end" }); }} >Change End Cutoff Time</Button>
                                </div>
                            </Form.Field>
                        </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br /><br /><br /><br />
                    <div className={this.state.showScheduleDiv_CSS}>
                        <Grid className="ui two column grid" divided>
                            <Grid.Column className="Admin-BG-Orange" width="7">
                                <h2><u>Appointment List</u></h2>
                                <Item.Group divided>
                                    {
                                        (this.state.dataAgentApptList != null && this.state.dataAgentApptList.length > 0) ?
                                            this.state.dataAgentApptList.map(
                                                (item) =>
                                                    <Item key={item.apptId}>
                                                        <Item.Content>
                                                            <div className="Admin-DivInlineBlock">
                                                                <Item.Header className={"Admin-Large-Font"}>{item.apptReference === "START" ? item.apptReference + " - " + this.state.routingStartTime : item.apptReference + " - Arrival: " + moment(item.estimatedArrivalTime).format("HH:mm a") + " (Travel: ~" + Math.round(item.legDurationSeconds / 60) + "mins)"}</Item.Header>
                                                                <Item.Meta>{(item.surname !== "" ? (item.surname + ", ") : "") + item.apptDescription + ", " + item.postcode}</Item.Meta>
                                                            </div>
                                                            <div className="Admin-DivInlineBlock Admin-Div-Float-Right">
                                                                {item.apptId > 0 && !item.completed && !item.cancelled ? <Button className={"ui red button " + this.state.cancelButton_CSS} value={item.apptId} onClick={this.onClick_CancelButton}>Cancel</Button> : item.completed ? <Item.Header className={"Admin-Medium-Font"}>COMPLETED</Item.Header> : item.cancelled ? <Item.Header className={"Admin-Medium-Font Admin-Red-Bold"}>CANCELLED</Item.Header> : ""}
                                                                {item.apptId > 0 && !item.completed && !item.cancelled ? <Button className={"ui green button " + (item.sentBookingSMS ? "disabled " : "") + this.state.smsButton_CSS} value={item.apptId} num={item.telephone} onClick={this.onClick_SmsButton}>Send SMS</Button> : ""}
                                                                {!(item.apptId > 0) ? <Button className={"ui yellow button " + this.state.alterPostcodeButton_CSS} value={item.apptReference} onClick={this.onClick_alterPostcodeButton}>Alter Postcode</Button> : ""}
                                                            </div>
                                                            {item.postcodeLat === 0.0 ? <div className="Admin-Red-Bold">GPS Lat: {item.postcodeLat}, Lon: {item.postcodeLon}</div> : <div></div>}
                                                            {item.clientNeedsDoubleAppt ? <div className="Admin-Red-Bold Admin-Large-Font">DOUBLE APPT!</div> : <div></div>}
                                                        </Item.Content>
                                                    </Item>
                                            )
                                            :
                                            <Item></Item>
                                    }
                                </Item.Group>
                                <h2 className={"Admin-Red-Bold h2-margin " + this.state.afterEndCutoff_CSS} >Schedule finishing after agent routing end cutoff time!</h2>
                                <h2 className={"h2-margin "} >Agent routing end cutoff time = {this.state.scheduleRoutingEndTime}</h2>
                                <br />
                                <Button className={"blue " + this.state.bookModalButton_CSS} onClick={this.onClickBookApptButton}>Book Appointment</Button>
                                <Button className={"purple " + this.state.createScheduleButton_CSS} onClick={() => this.createSchedule(this.props.url)}>Create Schedule</Button>
                            </Grid.Column>
                            <Grid.Column className="Admin-BG-Blue" width="9">
                                <div style={{ height: this.state.mapH, width: this.state.mapW }}>
                                    <AdminGoogleMap
                                        polylineDecoded={this.state.polylineDecoded}
                                        dataAgentApptList={this.state.dataAgentApptList}
                                        mapZoom={this.state.mapZoom}
                                        mapCentre={this.state.mapCentre}
                                    ></AdminGoogleMap>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </div>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.httpResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ httpResultModalOpen: false, editApptsLoading: "" })}>
                        <Header><Icon className={this.state.httpResultModalIcon} />{this.state.httpResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.httpResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={
                                () => {
                                    this.setState({ httpResultModalOpen: false });
                                    if (this.state.schedCreatedOK || this.state.cancelSuccess || this.state.alterSuccess) {
                                        this.setState({
                                            cancelSuccess: false,
                                            alterSuccess: false
                                        });
                                        this.loadAgentSchedule_Wrapper(this.props.url);
                                    };
                                }}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editTimeModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ editTimeModalOpen: false, editTimeLoading: "" })}>
                        <Header><Icon className={"ui icon clock"} />Edit Agent Schedule {this.state.changeStartOrEnd === "start" ? "Start" : "End Cutoff"} Time</Header>
                        <Modal.Content>
                            <div>
                                Select new {this.state.changeStartOrEnd === "start" ? "Start" : "End Cutoff"} Time:&emsp;
                                <Dropdown placeholder="Select Hour" scrolling options={this.state.timeOptions_Hour} onChange={this.onChangeNewTimeHours} value={this.state.newTime_Hour}></Dropdown>
                                &emsp;:&emsp;
                                <Dropdown placeholder="Select Minutes" scrolling options={this.state.timeOptions_Minute} onChange={this.onChangeNewTimeMinutes} value={this.state.newTime_Minutes}></Dropdown>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={
                                () => {
                                    this.setState({ editTimeModalOpen: false, dimmerActive: true });
                                    this.state.changeStartOrEnd === "start" ? this.updateStartTime_Wrapper(this.props.url) : this.updateEndTime_Wrapper(this.props.url);
                                }}><Icon name='checkmark' />&nbsp;Update</Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.bookApptModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ bookApptModalOpen: false, bookModalButton_CSS: "" })}>
                        <Header><Icon className={"icon address card"} />Book new Appointment for Agent</Header>
                        <Modal.Content>
                            <div>
                                <div className="has-text-centered has-text-weight-semibold">
                                    <br /><br />
                                    <div className={this.state.divFileInfo_CSS}>
                                        <h2>{"Matter selected: " + this.state.fileInfo}</h2>
                                    </div>
                                    <div className={this.state.divMatterSearch_CSS}>
                                        <div className="Div-Matter-Dropdown">
                                            <Form>
                                                <Form.Field>
                                                    <div>
                                                        <label>Enter Matter Reference:</label>
                                                        <div className="Div-Matter-Dropdown-Inner">
                                                            <Dropdown
                                                                fluid={true}
                                                                selection={true}
                                                                search={true}
                                                                placeholder="Enter Matter Reference..."
                                                                value={this.state.fileId}
                                                                options={this.state.matterOptions}
                                                                onChange={this.handleMatterChange}
                                                                onSearchChange={this.handleSearchChange}
                                                                loading={this.state.searching}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Field>
                                            </Form>
                                        </div>
                                    </div>
                                    <h2>{moment(this.state.schedDate).format('MMMM Do YYYY')}</h2>
                                </div>
                                <div className="has-text-centered">
                                    <br /><br />
                                    <Checkbox label="Appt. at POA address?" disabled checked={(this.state.apptAtPoa === true)} />
                                    <br /><br />
                                    <Label pointing="right">POA/Custom Postcode:</Label>
                                    <Input className="ui" type="text" value={this.state.input_appt_custom_postcode} onChange={this.valueChange('input_appt_custom_postcode')} />&emsp;
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => { this.setState({ bookApptModalOpen: false, bookModalButton_CSS: "" }); }}>
                                <Icon className="reply icon" />
                                Back
                            </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <Button className={"green " + this.state.bookingButton_CSS} size="small" onClick={() => {
                                this.setState({
                                    dimmerActive: true,
                                    bookingButton_CSS: "disabled loading"
                                });
                                this.bookAppt_Wrapper();
                            }}>
                                <Icon className="checkmark" />&nbsp;Book
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Appt-Modal-Booked" open={this.state.bookedModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ bookedModalOpen: false })}>
                        <Header ><Icon className={this.state.bookingResultIcon} />{this.state.bookingResultHeader}</Header>
                        <Modal.Content>
                            {this.state.bookingResultText}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                if (this.state.apptBookedOK) {
                                    //window.location.reload();
                                    console.log("has url id? " + (this.props.history.location.search.split("id=")[1] > 0));
                                    var urlHasFileId = (this.props.history.location.search.split("id=")[1] > 0)
                                    if (!urlHasFileId) {
                                        console.log("resetting file id info...");
                                        this.setState({
                                            fileId: 0,
                                            fileInfo: "",
                                            divFileInfo_CSS: "IQ-Hidden",
                                            divMatterSearch_CSS: "",
                                        });
                                    }
                                    this.loadAgentSchedule_Wrapper(this.props.url);
                                }
                                this.setState({
                                    bookedModalOpen: false,
                                    apptBookedOK: false,
                                });
                            }}>Close</Button>
                            <br /><br />
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Appt-Modal-Booked" open={this.state.cancelModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ cancelModalOpen: false })}>
                        <Header ><Icon className="ui icon cancel"></Icon>Cancel Appointment</Header>
                        <Modal.Content>
                            Are you sure that you wish to cancel this appointment?
                            <br /><br />
                            Please select a reason:
                            <br /><br />
                            <Dropdown className="Admin-Modal-CancelApptReason" placeholder="Cancellation Reason" options={cancelOptions} selection={true} value={this.state.cancelReason} onChange={this.handleCancelReasonChange} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => { this.setState({ cancelModalOpen: false, cancelButton_CSS: "" }); }}>
                                <Icon className="reply icon" />
                                Back
                            </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <Button className={"green " + this.state.cancelModalButton_CSS} size="small" onClick={() => {
                                this.setState({
                                    cancelModalButton_CSS: "disabled loading"
                                });
                                this.onClick_CancelModalButton();
                            }}>
                                <Icon className="checkmark" />&nbsp;Cancel Appt
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Appt-Modal-Booked" open={this.state.alterModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ alterModalOpen: false })}>
                        <Header ><Icon className="ui icon edit"></Icon>Alter {this.state.sched_appt_reference === "START" ? "Start" : "Finish"} Postcode</Header>
                        <Modal.Content>
                            <Label pointing="right">New Postcode:</Label>
                            <Input className="ui" type="text" value={this.state.input_sched_custom_postcode} onChange={this.valueChange('input_sched_custom_postcode')} />&emsp;
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => { this.setState({ alterModalOpen: false }); }}>
                                <Icon className="reply icon" />
                                Back
                            </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <Button className={"green " + this.state.alterModalButton_CSS} size="small" onClick={() => {
                                this.setState({
                                    alterModalButton_CSS: "disabled loading"
                                });
                                this.onClick_alterPostcodeSubmit();
                            }}>
                                <Icon className="checkmark" />&nbsp;Alter Postcode
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Appt-Modal-Booked" open={this.state.smsModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ smsModalOpen: false })}>
                        <Header ><Icon className="ui icon envelope"></Icon>Send SMS To Client</Header>
                        <Modal.Content>
                            <Segment className={this.state.showDEFAULTNotificationchoice_CSS}>
                                <Label pointing="right">We will try to send to this number:</Label>
                                <Input className="ui" type="text" disabled value={this.state.input_sched_sms_num} onChange={this.valueChange('input_sched_sms_num')} />&emsp;
                                <br /><br />
                                <p>NOTE: If the above number is either missing (null), a landline or an invalid mobile number, <br />&emsp;we will try and fix/update this before sending the SMS.<br /><br />So please attempt at least 1 SMS send to trigger the number repair process.</p>
                            </Segment>
                            <Segment className={this.state.showPOANotificationchoice_CSS}>
                                Case is using a POA - Pick who will receive appt booking notifications:<br /><br />
                                <div className="Div-Matter-Dropdown">
                                    <Form>
                                        <Form.Field>
                                            <div>
                                                <div className="Div-Matter-Dropdown-Inner">
                                                    <Dropdown
                                                        fluid={true}
                                                        selection={true}
                                                        search={false}
                                                        placeholder="Choose notification recipient..."
                                                        value={this.state.chosenMobileOption}
                                                        options={this.state.poaMobileOptions}
                                                        onChange={this.handleMobileOptionChange}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Field>
                                    </Form>
                                </div>
                            </Segment>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => { this.setState({ smsModalOpen: false, smsButton_CSS: "" }); }}>
                                <Icon className="reply icon" />
                                Back
                            </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <Button className={"green " + this.state.smsModalButton_CSS} size="small" onClick={() => {
                                this.setState({
                                    smsModalButton_CSS: "disabled loading"
                                });
                                this.onClick_smsModalSubmit();
                            }}>
                                <Icon className="checkmark" />&nbsp;Send SMS
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default BookApptPage;