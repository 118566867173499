import React, { Component } from 'react';
import { Segment, Label, Icon, Form, Dimmer, Loader } from 'semantic-ui-react';
import './AdminPage.css';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';

var moment = require('moment');

class AgentApptsForDate extends Component{
    constructor(props){
        super(props);
        this.editSegment = React.createRef();
        this.state = {
            schedDate: moment(new Date()),
            agentSchedules: [],
            dimmerActive: false,
            tabDimmer_Active: false
        }
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.onChange_newSchedDate = this.onChange_newSchedDate.bind(this);
        this.getAgentScheduleSummaryForDate_Wrapper = this.getAgentScheduleSummaryForDate_Wrapper.bind(this);
        this.getAgentScheduleSummaryForDate = this.getAgentScheduleSummaryForDate.bind(this);
        //
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    //
    //
    onChange_newSchedDate(value){
        //console.log("picker value: " + value.toString());
        this.setState({
            dimmerActive: true,
        });
        var d = moment(value, 'MM-DD-YYYY').add(3, "h"); //offset time to avoid timezone & BST issues
        //console.log("new sched date = " + d.toString());
        //
        this.setState({
            schedDate: d,
        });
        //
        this.getAgentScheduleSummaryForDate_Wrapper(this.props.url, d);
    }
    //
    getAgentScheduleSummaryForDate_Wrapper(url, date){
        //console.log("sending passed date = " + date.toString());
        var result = this.getAgentScheduleSummaryForDate(url, date);
        result.then((data) => {
            this.setState({
                dimmerActive: false,
            });
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        agentSchedules: data.body,
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async getAgentScheduleSummaryForDate(url, date){
        const response = await fetch(url + "/Schedule/GetAgentScheduleSummaryForDate", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleDate: date,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="map large"/>&emsp;Agent Schedule Overview</h5></Label>
                    <br/><br/><br/>
                    <div>
                    <Form>
                        <Form.Field className={"Admin-Div-CreateSchedule"}>
                            <br/><br/><br/><br/>
                            <label className="Admin-CreateScheduleLabel">Select Date to View</label>
                            <div className="Admin-DivCalendar">
                                <Calendar
                                    id= "input_scheduleDate"
                                    format='ddd DD/MMM/YYYY'
                                    displayYrWithMonth={true}
                                    closeOnSelect={true}
                                    openOnInputFocus={true}
                                    //minDate = {(new Date()).getDate() + "/" + (new Date()).getMonth() + "/" + (new Date()).getFullYear()}
                                    minDate = {"1/11/2019"}
                                    readOnly={true}
                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                    date={this.state.schedDate}
                                    onChange={this.onChange_newSchedDate}
                                    locale="en-gb"
                                    //customIcon="calendar outline icon"
                                />
                            </div>
                        </Form.Field>
                    </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/>
                    <div className={this.state.formCSS}>
                        <Segment attached="top">
                            <Dimmer active={this.state.tabDimmer_Active}>
                                <Loader>Loading</Loader>
                            </Dimmer>
                            <Form>
                                <Form.Field>
                                    <div className="Admin-Div-Company-Region div-postcode-wrapper">
                                        {this.state.agentSchedules.map(
                                            (item, i) => {
                                                return(
                                                <div className={i} key={i}>
                                                    <font>Agent:&emsp;{item.AgentName}&emsp;&emsp;&emsp;&emsp;Number of appts:&emsp;{item.ApptCount}</font>
                                                    {i !== this.state.agentSchedules.length-1 ? <hr/> : ""}
                                                </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </Form.Field>
                            </Form>
                        </Segment>
                        <br/><br/>
                    </div>
                </Segment>
            </div>
        );
    }
}

export default AgentApptsForDate;